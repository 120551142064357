import React from 'react';
import WinnersSectionWebView from './WinnersSectionWebView';
import WinnersSectionMobileView from './WinnersSectionMobileView';
import winnersData from './winnersData.json';

const WinnersSection = () => {
  return (
    <>
      <div className="block md:hidden">
        <WinnersSectionMobileView winnersData={winnersData} />
      </div>
      <div className="hidden md:block">
        <WinnersSectionWebView winnersData={winnersData} />
      </div>
    </>
  );
};

export default WinnersSection;
