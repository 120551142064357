import React, { useRef, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

import './Slides.css';
import GlassCardMobile from '../GlassCards/GlassCardsMobile';

const SlidesMobile = ({ winnersData, currentSlideIndex, incrRoleIndex }) => {
  const swiperRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current && currentSlideIndex !== undefined) {
      swiperRef.current.swiper.slideTo(currentSlideIndex);
    }
  }, [currentSlideIndex]);
  return (
    <div className="slides-container flex w-full items-center justify-start">
      <Swiper
        ref={swiperRef}
        rewind={true}
        className="items-center "
        wrapperClassName="swiper-wrapper "
        autoHeight={true}
        allowTouchMove={false}
      >
        {winnersData.roleTransition.map((role, index) => {
          return (
            <SwiperSlide key={index}>
              <GlassCardMobile
                role={role}
                usersData={winnersData.transitionData[role]}
                currentSlide={index}
                currentSlideIndex={currentSlideIndex}
                incrRoleIndex={incrRoleIndex}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default SlidesMobile;
