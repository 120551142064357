import React from 'react';
import Layout from '@components/v5/layouts/Layout/index';
import Slides from '../Slides/Slides.js';

const WinnersSectionWebView = ({ winnersData }) => {
  return (
    <Layout>
      <div className="relative z-[999] flex flex-col items-center gap-14 text-center text-white">
        <h1 className="text-[24px] md:text-[48px]">
          Meet Our{' '}
          <span className="border-b-2 border-white">
            Crio Hall Of Fame Winners Of 2023
          </span>
        </h1>
        {/* <GlassCard /> */}
        <Slides winnersData={winnersData} />
      </div>
    </Layout>
  );
};

export default WinnersSectionWebView;
