import React from 'react';

import NavbarWeb from './NavbarWeb';
import NavbarMobile from './NavbarMobile';

const Navbar = () => {
  // const isMobile = useResizer();

  return (
    <>
      <div className="md:hidden">
        <NavbarMobile />
      </div>
      <div className="hidden md:block">
        <NavbarWeb />
      </div>
    </>
  );
};

export default Navbar;
