import React from 'react';
import Layout from '@components/v5/layouts/Layout/index';
import playBtnIcon from '../../../images/hall-of-fame/play-btn-icon.webp';
import awardCeremonyImg from '../../../images/hall-of-fame/award-ceremony.webp';

const AwardCeremonyVideoSection = () => {
  return (
    <Layout className="flex justify-center">
      <div className="border-gradient-radius30 border-gradient gradient-card-dark relative z-[999] flex flex-col items-center justify-between gap-10 rounded-[30px] p-8 py-10  text-center text-white shadow-[0px_4px_10px_-0px_#FFCD6B33] md:flex-row md:gap-14 md:text-left">
        <div className="font-manrope font-bold">
          <p className="text-[18px] md:text-[34px]">
            Watch Crio Hall of Fame 2023
          </p>
          <p className="text-[18px] md:text-[34px]">Award Ceremony Here</p>
        </div>
        <div className="relative">
          <div className="relative">
            <img
              src={awardCeremonyImg}
              className="h-[156px] w-[276px] object-cover brightness-50 md:h-[230px] md:w-[400px]"
              alt=""
            />
          </div>

          <a
            className="absolute top-0 left-0 flex h-full w-full items-center justify-center"
            href="https://www.youtube.com/playlist?list=PLrKcFCERg7yYMlz_XThh44uHxwdJfxHmx"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={playBtnIcon}
              className="h-[48px] w-[48px] md:h-[71px] md:w-[71px]"
            />
          </a>
        </div>
      </div>
    </Layout>
  );
};

export default AwardCeremonyVideoSection;
