import React from 'react';

export default function CrioGraduateStats({ children, numStats, statsInfo }) {
  return (
    <div className="h-full w-[200px] text-center">
      <div className="relative z-[999] mx-auto flex h-full flex-col items-center gap-5">
        {children}
        <p className="text-3xl font-bold">{numStats}</p>
        <p className="mx-auto text-center">{statsInfo}</p>
      </div>
    </div>
  );
}
