import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';

import './Slides.css';
import GlassCard from '../GlassCards/GlassCards';

const Slides = ({ winnersData }) => {
  return (
    <div className="slides-container flex w-full items-center justify-start">
      <Swiper
        rewind={true}
        modules={[Navigation]}
        navigation={true}
        className="items-center "
        wrapperClassName="swiper-wrapper "
        autoHeight={true}
        style={{
          '--swiper-navigation-color': '#000',
          '--swiper-navigation-size': '25px',
        }}
      >
        {winnersData.roleTransition.map((role) => {
          return (
            <SwiperSlide key={role}>
              <GlassCard role={role} data={winnersData.transitionData[role]} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default Slides;
