import React from 'react';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';

export default function HeroContentLeft() {
  return (
    <div className=" container relative z-[10] flex w-[90%] flex-col gap-8 text-center font-thin text-white md:justify-between md:gap-0 md:text-left">
      <div className="flex justify-center text-center md:justify-start md:text-left">
        <div
          className="text-[36px] font-bold md:text-[48px]"
          style={{
            backgroundImage:
              'linear-gradient(97.43deg, #A66B32 -10.04%, #B17739 -5.52%, #CE974C 5.04%, #FFCD6B 17.1%, #FFEAC1 42.74%, #FFDE9E 53.29%, #FFCD6B 69.88%, #C68F47 94.01%, #AE7437 106.07%, #EDBA5F 108.51%, #A66B32 113.61%, #B17739 124.17%, #CB944A 140.76%)',
            WebkitBackgroundClip: 'text',
            backgroundClip: 'text',
            backgroundSize: 'contain',
            color: 'transparent',
          }}
        >
          Crio Hall Of Fame 2023
        </div>
      </div>

      <div className="text-[16px] md:text-[20px]">
        Crio Hall of Fame is our{' '}
        <strong className="font-semibold">annual award ceremony</strong>{' '}
        to recognize and celebrate our outstanding{' '}
        <strong className="font-semibold">success stories of 2023.</strong>
      </div>

      <div className="mb-0 text-[16px] md:mb-6 md:text-[20px]">
        This year we are on a mission to{' '}
        <strong className="font-semibold">magnify our impact</strong> even
        further with our{' '}
        <strong className="font-semibold">Crio Mission Scholarship</strong>
      </div>

      <div className="md:w-[280px]">
        <LeadGenerationButton
          type="Apply Now"
          text="Apply Now"
          buttonLocation="HALL_OF_FAME_HERO"
          formHeading={'Book your Slot'}
          pageOneButtonText={'Apply Now'}
          pageTwoButtonText={'Apply Now'}
          applicationPage={'HALL_OF_FAME'}
          className="h-[70px] text-[20px] md:w-[280px]"
        />
      </div>
    </div>
  );
}
