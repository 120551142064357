import React from 'react';
import scholarshipIcon from '../../../images/hall-of-fame/Scholarship.webp';

const UnlockCrioScholarship = () => {
  return (
    <section className="mx-auto flex justify-center py-10 text-center text-white">
      <div className="relative z-[999]">
        <h1 className="mb-4 text-[24px] font-bold md:text-[48px]">
          Unlock Crio Mission Scholarship
        </h1>
        <p className="text-[20px] font-thin">
          Avail special Crio Mission Scholarship & become part of Crio Hall of
          Fame in 2024!
        </p>
        <p className="text-[20px] font-thin">
          This scholarship is valid till 31st January 2024.
        </p>
        <div className="relative mb-[60px] mt-[70px]">
          <div className="border-gradient-radius30 border-gradient gradient-card-dark  flex flex-col items-center rounded-[30px] pt-10 pb-4 text-white backdrop-blur">
            <img
              src={scholarshipIcon}
              alt=""
              className="absolute top-[-40px] h-[70px] w-[70px] md:top-[-32px] md:h-[80px] md:w-[80px]"
            />
            <p className="m-2 text-[18px] font-bold md:text-[34px]">
              Scholarships upto Rs. 70,000
            </p>
            <p className="px-2 font-thin text-gray-200">
              *This scholarship cannot be clubbed with other offers
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UnlockCrioScholarship;
