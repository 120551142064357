import React, { useState } from 'react';
import crioLogo from '../../../images/hall-of-fame/crio-logo.webp';
import GlassCircleBlur from '@components/GlassMorphism/GlassCircleBlur/index';
import HallOfFameLogo from '../../../images/hall-of-fame/HallOfFameLogo.webp';

const navItems = [
  { sectionId: 'scholarship', label: 'Scholarship' },
  { sectionId: 'winners', label: 'Winners' },
  { sectionId: 'benefits', label: 'Benefits' },
];

const MobileNav = () => {
  const [isOpen, setIsOpen] = useState(false);

  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleNavItemClick = (sectionId) => {
    setIsOpen(false);
    scrollToElement(sectionId);
  };

  return (
    <>
      <nav className="relative z-[99] h-[54px]  bg-[#013437] text-white">
        <div className="mx-auto flex h-full w-[90%] items-center justify-between">
          <a href="https://www.crio.do/" target="_blank" rel="noreferrer">
            <img src={crioLogo} height={30} width={100} />
          </a>
          {/* <div className="relative z-[999]">
            {!isOpen ? (
              <FontAwesomeIcon
                size="1x"
                icon={['far', 'bars']}
                onClick={() => {
                  setIsOpen(true);
                }}
                className="cursor-pointer"
              />
            ) : (
              <FontAwesomeIcon
                size="1x"
                icon={['far', 'times']}
                onClick={() => {
                  setIsOpen(false);
                }}
                className="cursor-pointer"
              />
            )}
          </div> */}
        </div>
      </nav>
      {isOpen ? (
        <div className="fixed z-[1000] flex h-screen w-screen flex-col items-center gap-14 bg-[#0c1b12] py-14 text-white">
          <GlassCircleBlur extendedClassName="absolute bg-gradient-to-t from-v5-green-600 to-v5-green-950 right-8 top-[10%]" />

          <div className="relative z-[2000]">
            <img
              src={HallOfFameLogo}
              alt="Hall Of Fame"
              height={83}
              width={191}
            />
          </div>

          {navItems.map((item) => (
            <NavItem
              key={item.sectionId}
              {...item}
              onClick={handleNavItemClick}
            />
          ))}
        </div>
      ) : null}
    </>
  );
};

const NavItem = ({ sectionId, label, onClick }) => {
  return (
    <div
      className="transform cursor-pointer text-[18px] capitalize text-white"
      onClick={() => onClick(sectionId)}
    >
      <span>{label}</span>
    </div>
  );
};

export default MobileNav;
