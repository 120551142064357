import React from 'react';
import crioMission from '../../../images/hall-of-fame/crio-mission.webp';
import Layout from '@components/v5/layouts/Layout/index';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';

const CrioMissionScholarship = () => {
  return (
    <Layout>
      <div className="relative z-[999] flex flex-col text-white">
        <div className="w-full">
          <h2 className="mb-10 text-center text-[24px] md:text-[48px]">
            Crio Mission Scholarship
          </h2>
        </div>
        <div className="mx-auto flex w-[90%] flex-col items-center gap-10 md:w-full md:flex-row">
          <div>
            <img
              src={crioMission}
              alt=""
              style={{ maxHeight: '340px', maxWidth: '340px', height: '50vw' }}
            />
          </div>
          <div className="flex flex-col justify-between gap-7 py-3 text-center text-[12px] font-thin md:text-left md:text-[20px]">
            <p>
              Witnessing the impact we had on the careers of our Crio grads in
              2023, we are on a mission to{' '}
              <strong className="font-semibold">
                empower more tech aspirants
              </strong>{' '}
              to achieve their career goals in 2024. Introducing{' '}
              <strong>Crio Mission Scholarship</strong>, our commitment to
              nurture more talented minds of our country who can make a{' '}
              <strong>difference in the tech industry.</strong>
            </p>
            <p className="font-semibold text-[#2DF8C5]">
              Avail up to ₹70,000 scholarship. Valid till 31st January, 2024.
            </p>
            <p className="font-thin text-gray-200">
              *This scholarship cannot be clubbed with other offers
            </p>
            <div className="md:w-[280px]">
              <LeadGenerationButton
                type="Apply Now"
                text="Apply Now"
                buttonLocation="HALL_OF_FAME_CRIO_MISSION_SCHOLARSHIP"
                formHeading={'Book your Slot'}
                pageOneButtonText={'Apply Now'}
                pageTwoButtonText={'Apply Now'}
                applicationPage={'HALL_OF_FAME'}
                className="h-[70px] text-[20px] md:w-[280px]"
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CrioMissionScholarship;
