import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import '../Slides/Slides.css';

const template = 'Exceptional Career Transition:';

const Tabs = ({ winnersData, setRole, roleIndex }) => {
  return (
    <div className="slides-container w-[90%]">
      <Swiper
        slidesPerView="auto"
        modules={[Navigation]}
        className="items-center"
        wrapperClassName="swiper-wrapper"
        autoHeight={true}
        spaceBetween={5}
      >
        {winnersData.roleTransition.map((role, i) => {
          return (
            <SwiperSlide key={i}>
              <div
                className={`${
                  roleIndex === i
                    ? 'border-gradientv3 border-gradient-radius30 font-semibold text-black'
                    : ''
                } rounded-[30px] py-3 px-4 text-[12px]`}
                onClick={() => setRole(i)}
                style={{
                  backgroundImage: `${
                    roleIndex === i
                      ? 'linear-gradient(104.74deg, #2DF8C5 20.05%, #07634C 100%),linear-gradient(101.07deg, rgba(45, 248, 197, 0) 12.03%, #2DF8C5 99.44%)'
                      : ''
                  }`,
                }}
              >
                {role.split(template)[role.includes(template) ? 1 : 0]}
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default Tabs;
