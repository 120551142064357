import React from 'react';
import Card from '../Card/Card';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import WatchVideoBtn from '../WatchVideoBtn/WatchVideoBtn';
import VideoModal from '../VideoModal/VideoModal';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import SwiperCore, { Autoplay } from 'swiper';

SwiperCore.use([Autoplay]);

const template = 'Exceptional Career Transition:';

export default function GlassCard({ role, data }) {
  const [open, setOpen] = React.useState(false);
  const shouldDisableAutoplay = data.length <= 4;

  return (
    <div
      className={`border-gradient-radius30 border-gradient gradient-card-dark z-[999] w-[90%] gap-8 overflow-hidden rounded-[30px] p-8 backdrop-blur`}
    >
      <div className="flex flex-col items-center justify-center gap-3  py-4">
        <div
          className="absolute top-0 right-0 hidden px-5 py-2 text-[18px] font-bold text-[#013437] md:block"
          style={{
            borderBottomLeftRadius: '30px',
            background:
              'linear-gradient(97.43deg, #A66B32 -10.04%, #B17739 -5.52%, #CE974C 5.04%, #FFCD6B 17.1%, #FFEAC1 42.74%, #FFDE9E 53.29%, #FFCD6B 69.88%, #C68F47 94.01%, #AE7437 106.07%, #EDBA5F 108.51%, #A66B32 113.61%, #B17739 124.17%, #CB944A 140.76%)',
          }}
        >
          WINNERS
        </div>

        <div className="text-center text-[18px] md:text-[34px]">
          {role.includes(template) ? template : null}
          <strong>
            {role.includes(template)
              ? role.split(template)[1]
              : role.split(template)[0]}
          </strong>
        </div>
        <div className="slides-container mx-auto flex w-[90%] justify-center">
          <Swiper
            freeMode={true}
            slidesPerView="auto"
            modules={[Navigation]}
            spaceBetween={20}
            // loop={true}
            autoplay={
              !shouldDisableAutoplay && {
                delay: 0,
                disableOnInteraction: false,
              }
            }
            speed={6000}
            className={`${data.length <= 3 ? 'w-[650px]' : ''} items-center`}
            wrapperClassName="swiper-wrapper"
            autoHeight={true}
            intitialSlide={0}
          >
            {data.map((user) => (
              <SwiperSlide key={user}>
                <Card
                  key={user.name}
                  user={user}
                  extendedClassName="w-[200px] h-[340px]"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        {/* Button container */}
        <div className="flex gap-10 pt-5">
          <WatchVideoBtn toggleModal={setOpen} isModalOpen={open} />
          <LeadGenerationButton
            type="Apply Now"
            text="Apply Now"
            buttonLocation="HALL_OF_FAME_WINNERS_SECTION_WEB"
            formHeading={'Book your Slot'}
            pageOneButtonText={'Apply Now'}
            pageTwoButtonText={'Apply Now'}
            applicationPage={'HALL_OF_FAME'}
            className="h-[70px] w-[280px] text-[20px]"
          />
        </div>
      </div>
      {/* <RawHTML className="text-crio-neutral-200">{card.description}</RawHTML> */}
      <VideoModal open={open} setOpen={setOpen} role={role} />
    </div>
  );
}
