import React from 'react';

export default function GlassCircleBlur({ extendedClassName }) {
  return (
    <div
      className={`h-[500px] w-[500px] rounded-full blur-[200px] ${extendedClassName} -z-1`}
    ></div>
  );
}
//  blur-[200px]
