import React from 'react';
import crioLogo from '../../../images/hall-of-fame/crio-logo.webp';
import HallOfFameLogo from '../../../images/hall-of-fame/HallOfFameLogo.webp';

const WebView = () => {
  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <nav className="fixed z-[1000] h-[100px] w-[100dvw]  bg-hall-of-fame-navbar">
      <div className="mx-auto flex h-full max-w-[1340px] items-center justify-around md:w-[90%]">
        <a href="https://www.crio.do/" target="_blank" rel="noreferrer">
          <img src={crioLogo} height={30} width={100} />
        </a>
        <NavLink
          label="Scholarship"
          sectionId="scholarship"
          onClick={scrollToElement}
        />
        <div>
          <img
            src={HallOfFameLogo}
            alt="Hall Of Fame"
            height={60}
            width={160}
          />
        </div>
        <NavLink
          label="Winners"
          sectionId="winners"
          onClick={scrollToElement}
        />
        <NavLink
          label="Benefits"
          sectionId="benefits"
          onClick={scrollToElement}
        />
      </div>
    </nav>
  );
};

const NavLink = ({ label, sectionId, onClick }) => (
  <div
    className="transform cursor-pointer text-[18px] capitalize text-white"
    onClick={() => onClick(sectionId)}
  >
    <span>{label}</span>
  </div>
);

export default WebView;
