// StatBlockRenderer.js
import React from 'react';
import CrioGraduateStats from './CrioGraduateStats';
import offersCrackedIcon from '../../../images/hall-of-fame/success-stories/offers_cracked.webp';
import salaryHikeIcon from '../../../images/hall-of-fame/success-stories/salary-hike.webp';
import hiringPartnerIcon from '../../../images/hall-of-fame/success-stories/hiring-partner-icon.webp';

//CONSTANT DATA:
const statBlocks = [
  {
    numStats: 586,
    statsInfo: 'Offers cracked by Crio graduates',
    iconSrc: offersCrackedIcon,
  },
  {
    numStats: '50% - 70%',
    statsInfo: 'Average salary hike based on salary buckets',
    iconSrc: salaryHikeIcon,
  },
  {
    numStats: 563,
    statsInfo: 'Hiring Partners in 2023',
    iconSrc: hiringPartnerIcon,
  },
];

const StatBlock = ({ numStats, statsInfo, iconSrc }) => (
  <React.Fragment>
    <CrioGraduateStats numStats={numStats} statsInfo={statsInfo}>
      <img src={iconSrc} alt="" className="h-[52px] w-[52px] object-contain" />
    </CrioGraduateStats>
  </React.Fragment>
);

const StatBlockRenderer = () => (
  <>
    {statBlocks.map((stat, index) => (
      <React.Fragment key={index}>
        <StatBlock {...stat} />

        {index < statBlocks.length - 1 && (
          <div className="hidden h-[150px] w-[2px] bg-[#004246] md:block"></div>
        )}
      </React.Fragment>
    ))}
  </>
);

export default StatBlockRenderer;
