import React, { useState } from 'react';
import Layout from '@components/v5/layouts/Layout/index';
import Tabs from './Tabs.js';
import SlidesMobile from '../Slides/SlidesMobile.js';

const WinnersSectionMobileView = ({ winnersData }) => {
  const [roleIndex, setRoleIndex] = useState(0);

  const incrRoleIndex = () => {
    if (roleIndex < winnersData.roleTransition.length) {
      setRoleIndex((prev) => prev + 1);
    }
  };
  return (
    <Layout>
      <div className="relative z-[999] flex flex-col items-center gap-7 text-center text-white">
        <h1 className="text-[24px] md:text-[48px]">
          Meet Our Crio Hall Of Fame Winners Of 2023
        </h1>
        {/* <GlassCard /> */}
        <Tabs
          winnersData={winnersData}
          setRole={setRoleIndex}
          roleIndex={roleIndex}
        />

        <SlidesMobile
          winnersData={winnersData}
          currentSlideIndex={roleIndex}
          incrRoleIndex={incrRoleIndex}
        />
      </div>
    </Layout>
  );
};

export default WinnersSectionMobileView;
