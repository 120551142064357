import { graphql } from 'gatsby';
import React from 'react';
import SEO from '@components/Common/SEO/index';
import FooterV2 from '@components/FooterV2/index';
import GlassCircleBlur from '@components/GlassMorphism/GlassCircleBlur/index';
import SuccessReport from '@components/HallOfFame/SuccessReport/SuccessReport';
import CrioMissionScholarship from '@components/HallOfFame/CrioMissionScholarship/CrioMissionScholarship';
import UnlockCrioScholarship from '@components/HallOfFame/UnlockCrioMissionScholarship/UnlockCrioMissionScholarship';
import AwardCeremonyVideoSection from '@components/HallOfFame/AwardCeremonyVideoSection/index';

import HeroSection from '@components/HallOfFame/HeroSection/HeroSection';
import Navbar from '@components/HallOfFame/Navbar/Navbar';
import WinnersSection from '@components/HallOfFame/WinnersSection/WinnersSection';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';

export default function HallOfFamePage(props) {
  return (
    <div className="relative">
      <SEO
        title={'Crio Hall Of Fame 2023'}
        description={
          'Crio Hall of Fame is our annual award ceremony to recognize and celebrate our outstanding success stories of 2023.'
        }
      ></SEO>
      {/* <Navigation dark /> */}
      <Navbar />

      <div className="h-full w-full bg-v5-green-700">
        <div className="relative md:pt-[100px]">
          <HeroSection />
        </div>
        <div className={`container relative px-2 md:py-2`}>
          <SuccessReport />
        </div>

        {/* <ApplyButton className="container justify-center" /> */}
        <div
          className={`container relative z-[999] mx-auto w-[90%] px-3 md:w-[280px]`}
        >
          <LeadGenerationButton
            type="Apply Now"
            text="Apply Now"
            buttonLocation="HALL_OF_FAME_SUCCESS_REPORT_SECTION"
            formHeading={'Book your Slot'}
            pageOneButtonText={'Apply Now'}
            pageTwoButtonText={'Apply Now'}
            applicationPage={'HALL_OF_FAME'}
            className="mt-10 h-[70px] text-[20px] md:w-[280px]"
          />
        </div>

        <div className="container relative pt-12" id="scholarship">
          <CrioMissionScholarship />
        </div>

        <div id="winners"></div>
        <div className={`container relative md:pt-12`}>
          <WinnersSection />
        </div>

        <div id="benefits"></div>
        <div className="container relative pt-12">
          <UnlockCrioScholarship />
        </div>

        <div className="container relative z-[999] mx-auto w-[90%] md:w-[280px]">
          <LeadGenerationButton
            type="Apply Now"
            text="Apply Now"
            buttonLocation="HALL_OF_FAME_UNLOCK_SCHOLARSHIP_SECTION"
            formHeading={'Book your Slot'}
            pageOneButtonText={'Apply Now'}
            pageTwoButtonText={'Apply Now'}
            applicationPage={'HALL_OF_FAME'}
            className="mb-10 h-[70px] text-[20px] md:w-[280px]"
          />
        </div>

        <div className="container">
          <AwardCeremonyVideoSection />
        </div>
      </div>
      <FooterV2 gradient />
      <div className="pointer-events-none fixed top-0 h-screen w-[calc(100vw-1rem)]">
        <GlassCircleBlur extendedClassName="absolute bg-gradient-to-t from-v5-green-100 to-v5-green-700 right-8 top-[10%]" />
        <GlassCircleBlur extendedClassName="absolute bg-black top-0 left-[2%]" />

        <GlassCircleBlur extendedClassName="absolute bg-gradient-to-t from-v5-green-100 to-v5-green-700 left-0 top-[55%]" />
        <GlassCircleBlur extendedClassName="absolute bg-black top-[50%] right-8" />
      </div>
    </div>
  );
}

export const pageQuery = graphql`
  query {
    sde2Yaml {
      slug
      canonicalUrl
      SEOTitle
      SEODescription
      PrimaryProgramID

      testimonialQuote

      faqs {
        question
        answer
      }

      price
      priceSectionTitle
      priceDuration
      priceHighlights {
        title
        description
      }

      stats {
        title
        subtitle
      }

      externshipHeading
      externshipDuration
      externshipDescription
      externshipChips
      externshipSvgTag

      heroDescription
      heroTitle
      heroSubDescription

      highlights {
        description
        svgType
        title
      }

      curriculumSectionTitle
      hldSvgTag
      hldCurriculumHeading
      hldCurriculumDescription
      hldCurriculum {
        title
        skills
        description
        image {
          childImageSharp {
            gatsbyImageData(
              width: 100
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
        duration
        tag
        data
      }

      lldSvgTag
      lldCurriculum {
        title
        description
        skills
        image {
          childImageSharp {
            gatsbyImageData(
              width: 100
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
        duration
        tag
        data
        cards {
          data
          skills
          title
        }
      }

      dsaSvgTag
      dsaCurriculum {
        title
        image {
          childImageSharp {
            gatsbyImageData(
              width: 100
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
        duration
        tag
        data
        cards {
          data
          skills
          title
        }
      }

      curriculumTitle
      curriculumDescription
      curriculumQuote

      videoSectionTitle

      featureSectionTitle
      features {
        description
        image {
          childImageSharp {
            gatsbyImageData(
              width: 400
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }

      careerTitle
      careerDescription
      careers {
        description
        image {
          childImageSharp {
            gatsbyImageData(
              width: 200
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  }
`;
