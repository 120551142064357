import React from 'react';
import StatBlockRenderer from './StatBlockRenderer';

const SuccessReport = () => {
  return (
    <section className="mx-auto flex w-full justify-center py-5 text-white md:py-10">
      <div className="relative z-[999] flex w-[90%] flex-col items-center gap-10 rounded-3xl bg-[#0c1b12] p-8 py-10 shadow-[0px_4px_10px_-0px_#FFCD6B33]">
        {/* Header */}
        <h2 className="text-center font-rubik text-3xl font-bold">
          Our 2023 Success Report
        </h2>

        <div className="flex w-[100%] flex-col items-center justify-around gap-14 font-manrope md:flex-row md:items-start md:gap-5">
          <StatBlockRenderer />
        </div>
      </div>
    </section>
  );
};

export default SuccessReport;
