import React from 'react';
import useResizer from '@components/extra/useResizer';
import Layout from '@components/v5/layouts/Layout/index';
import HeroContentLeft from './HeroContentLeft';
import HeroContentRight from './HeroContentRight';

const defaultOptions = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const HeroSection = () => {
  const isMobile = useResizer();
  return (
    <Layout>
      <div className="flex max-h-[1400px] max-w-[1366px] flex-col gap-14 xxl:mx-auto md:ml-auto md:max-h-[425px] md:w-[90%] md:flex-row md:gap-5">
        {/* <-------LEFT CONTAINER ---------> */}
        <HeroContentLeft />

        {/* < ---------GIF CONTAINER -----------> */}
        <HeroContentRight />
      </div>
    </Layout>
  );
};

export default HeroSection;
