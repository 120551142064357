import React, { useRef } from 'react';
import Card from '../Card/Card';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import WatchVideoBtn from '../WatchVideoBtn/WatchVideoBtn';
import VideoModal from '../VideoModal/VideoModal';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import SwiperCore, { Autoplay } from 'swiper';

SwiperCore.use([Autoplay]);

const template = 'Exceptional Career Transition:';

export default function GlassCard({ role, usersData, incrRoleIndex }) {
  const [open, setOpen] = React.useState(false);
  const swiperRef = useRef(null);

  // Progress to the next slide in the main swiper container upon reaching the end of the inner swiper.
  const handleSlideChangeTransitionEnd = () => {
    const swiperInstance = swiperRef.current.swiper;
    if (swiperInstance.isEnd) {
      incrRoleIndex();
    }
  };

  const renderContainerHeader = (role) => {
    return role.includes(template) ? (
      <>
        {template}
        <strong>{role.split(template)[1]}</strong>
      </>
    ) : (
      <strong>{role.split(template)[0]}</strong>
    );
  };

  return (
    <div
      className={`border-gradient-radius30 border-gradient gradient-card-dark z-[999] w-[90%] gap-8 overflow-hidden rounded-[30px] p-8 backdrop-blur`}
    >
      <div className="flex flex-col items-center justify-center gap-10">
        {/* ROLES TAB */}
        <div className="px-2 text-center text-[18px] md:text-[34px]">
          {renderContainerHeader(role)}
        </div>

        {/* MOBILE VIEW SWIPER */}
        <div className="slides-container w-[90%]">
          <Swiper
            ref={swiperRef}
            onSlideChangeTransitionEnd={handleSlideChangeTransitionEnd}
            slidesPerView={1.2}
            spaceBetween={20}
            modules={[Navigation]}
            speed={6000}
            className="items-center"
            wrapperClassName="swiper-wrapper "
            autoHeight={true}
          >
            {usersData.map((user, i) => (
              <SwiperSlide key={i}>
                <Card user={user} extendedClassName="w-[200px] h-[350px]" />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        {/* Button container */}
        <div className="flex w-full flex-col gap-10">
          <WatchVideoBtn toggleModal={setOpen} isModalOpen={open} />
          <LeadGenerationButton
            type="Apply Now"
            text="Apply Now"
            buttonLocation="HALL_OF_FAME_WINNERS_SECTION_MOBILE"
            formHeading={'Book your Slot'}
            pageOneButtonText={'Apply Now'}
            pageTwoButtonText={'Apply Now'}
            applicationPage={'HALL_OF_FAME'}
            className="w-full"
          />
        </div>
      </div>

      {/* YOUTUBE VIDEO MODAL */}
      <VideoModal open={open} setOpen={setOpen} role={role} />
    </div>
  );
}
