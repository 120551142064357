import React from 'react';
import webGifData from '../../../images/hall-of-fame/gifWithoutGradient.json';
import mobileGif from '../../../images/hall-of-fame/gifMobileData.json';
import useResizer from '@components/extra/useResizer';
import Lottie from 'react-lottie';

const defaultOptions = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export default function HeroContentRight() {
  const isMobile = useResizer();
  return (
    <div className="relative z-[1] w-full ">
      {isMobile ? (
        <Lottie options={{ ...defaultOptions, animationData: mobileGif }} />
      ) : (
        <Lottie options={{ ...defaultOptions, animationData: webGifData }} />
      )}
    </div>
  );
}
