import React, { useState } from 'react';
import youtubeLogo from '../../../images/hall-of-fame/youtube-logo.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const WatchVideoBtn = ({ toggleModal, isModalOpen }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  return (
    <button
      className={`${
        isHovered ? 'rounded-lg' : 'border-gradientv2'
      } border-gradient-radius10 z-[999] flex items-center gap-3 p-8 py-5 px-5 text-[16px] font-bold backdrop-blur md:py-0 ${
        isHovered ? 'text-black' : 'text-white'
      } md:w-[280px]`}
      style={{
        backgroundImage: isHovered
          ? 'linear-gradient(97.43deg, #A66B32 -10.04%, #B17739 -5.52%, #CE974C 5.04%, #FFCD6B 17.1%, #FFEAC1 42.74%, #FFDE9E 53.29%, #FFCD6B 69.88%, #C68F47 94.01%, #AE7437 106.07%, #EDBA5F 108.51%, #A66B32 113.61%, #B17739 124.17%, #CB944A 140.76%)'
          : 'none',
      }}
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
      onClick={() => toggleModal(!isModalOpen)}
    >
      <img src={youtubeLogo} height={37} width={26} />
      <span>Watch Video</span>
      <FontAwesomeIcon
        icon={['fas', 'angle-right']}
        className=" text-md mt-1"
        style={{ marginBottom: '3px' }}
      ></FontAwesomeIcon>
    </button>
  );
};

export default WatchVideoBtn;
