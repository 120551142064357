import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import winnersData from '../WinnersSection/winnersData.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '95vw',
  height: '75vw',
  maxWidth: '1040px',
  maxHeight: '585px',
  transform: 'translate(-50%, -50%)',
  //   bgcolor: 'background.paper',
  //   border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const DarkBackdrop = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.8)', // Adjust the opacity for darkness
  zIndex: 0, // Ensure it's above everything except the modal
});

const VideoModal = ({ open, setOpen, role }) => {
  return (
    <Modal
      open={open}
      onClose={() => setOpen(!open)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropComponent={DarkBackdrop}
    >
      <Box sx={style}>
        <FontAwesomeIcon
          icon={faTimes}
          onClick={() => setOpen(!open)}
          style={{
            position: 'absolute',
            top: '5px',
            right: '10px',
            zIndex: 1,
            color: 'white',
            cursor: 'pointer',
            height: '25px',
            width: '25px',
          }}
        />
        <iframe
          width="100%"
          height="100%"
          src={winnersData.youtubeEmbedLinks[role]}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </Box>
    </Modal>
  );
};

export default VideoModal;
