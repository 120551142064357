import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Card = ({ user, extendedClassName }) => {
  const data = useStaticQuery(graphql`
    query {
      userImages: allFile(
        filter: { relativeDirectory: { eq: "hall-of-fame/user-images" } }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      companyLogos: allFile(
        filter: { relativeDirectory: { eq: "hall-of-fame/company-logos" } }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  `);

  const userImage = data.userImages.nodes.find(
    (node) => node.name === user.img,
  );
  const companyLogo = data.companyLogos.nodes.find(
    (node) => node.name === user.company,
  );

  const userImageData = getImage(userImage);
  const companyLogoData = getImage(companyLogo);

  return (
    <div
      className={`border-gradient-radius30 border-gradient rounded-[30px] backdrop-blur ${extendedClassName}`}
      style={{
        background:
          'linear-gradient(101.07deg, rgba(45, 248, 197, 0) 12.03%, rgba(45, 248, 197, 0.3) 99.44%),linear-gradient(164.34deg, rgba(45, 248, 197, 0.3) 4.16%, rgba(7, 99, 76, 0.3) 100%)',
      }}
    >
      <div className="flex h-full flex-col">
        <div className="px-8 pt-8 pb-4">
          {userImageData && (
            <GatsbyImage image={userImageData} alt={user.name} />
          )}
        </div>
        <div className="flex h-full flex-col justify-between">
          <div>
            <div className="pb-4 text-[#2DF8C5]">{user.name}</div>
            <div className="text-[14px] font-thin">{user.designation}</div>
          </div>
          <div className="px-2 pb-5">
            {companyLogoData && (
              <GatsbyImage image={companyLogoData} alt="Company Logo" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
